<template>
  <div class="body-background">
      <SiteNavigation/>
      <router-view/>
      <div class="col-sm-6 offset-sm-3">
          <div v-if="alert.message" :class="`alert ${alert.type}`" style="text-align: center">{{alert.message}}</div>
      </div>
  </div>
</template>

<script>
import SiteNavigation from './components/SiteNavigation'

export default {
  name: 'App',
  components: {
    SiteNavigation,
  },
  computed: {
      alert () {
          return this.$store.state.alert
      }
  },
  watch:{
      //$route (to, from){
      $route (){
          // clear alert on location change
          this.$store.dispatch('alert/clear');
      }
  }
}
</script>

<style>
:root {
  --body-background: #21252b;
  --errorColor: #ff4a46;
  --okColor: green;
  --lockOkColor: blue;
  --lockWarningColor: peru;
  --noSignal: gray;
  --warningColor: orange;
  --terminalColor:  #494e56;
  --activeTerminalColor:  #383e4a;
}
html, body {
    height:  100%;
}
.body-background {
  height:  100%;
  background-color: var(--body-background);
  color: #ccc;
}
</style>
