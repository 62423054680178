import config from './config.json';
import Fingerprint2 from 'fingerprintjs2';
import axios from 'axios';

export const userService = {
    login,
    logout
};

function login(username, password) {
  return Fingerprint2.getPromise ( {excludeAdBlock: true} )
  .then(components => {
    const murmur = Fingerprint2.x64hash128(components.map(function (component) { return component.value }).join(''), 31);
    return axios.post (`${config.apiUrl}/users/authenticate`, { username: username, password: password, murmur: murmur, components: components })
    .then(response => {
      if (response.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(response.data))
      }
      return response.data
    })
    .catch(error => {
      if (error.response.status === 401) {
          // auto logout if 401 response returned from api
          logout();
          //location.reload(true);
      }
      //const error = (data && data.message) || error.response.statusText;
      return Promise.reject(error);
    })
  })
    /*return fetch(`${config.apiUrl}/users/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user))
            }

            return user
        })
      })*/
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}
/*
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text)
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    })
}
*/
