import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from '@/App.vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import infiniteLoading from 'vue-infinite-loading';
import { store } from './_store';
import { router } from './_helpers';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueSimpleAlert from "vue-simple-alert";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCgr3xv7xYxad_-pSsLjHnpP-he8psDxkM",
  }
});

Vue.use(VueSimpleAlert);
Vue.use(infiniteLoading);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

new Vue({
  el: '#app',
  store,
  router,
  App,
  render: h => h(App)
})
