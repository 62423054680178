<template>
  <div id="app">
  <b-navbar toggleable="md" type="dark" variant="dark">
    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
    <b-navbar-brand to="/"><img src="../assets/images/UMAI.svg" style="fill: '#17a2b8'" width="70" height="30" alt="альтернативный текст"></b-navbar-brand>
    <b-collapse is-nav id="nav_collapse">
      <b-navbar-nav>
        <b-nav-item-dropdown text="Платежи" v-if="loggedIn&&(isOwner||isBank||isDealer||isSupplier)&&!isCollector">
          <template v-slot:button-content>
            <b-icon icon="search"></b-icon>  <span class="sr-only">Search</span>
          </template>
          <b-dropdown-item to="/payments">Транзакции</b-dropdown-item>
          <b-dropdown-item to="/payment" v-if="(isOwner||isBank||isDealer)&&isTerminal">Провести платеж</b-dropdown-item>
          <b-dropdown-item to="/paymentlogs" v-if="(isOwner||isBank)&&!isTerminal">Логи платежей</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Отчеты" v-if="loggedIn&&(isOwner||isBank)&&!isTerminal&&!isCollector">
          <template v-slot:button-content>
            <b-icon icon="graph-up"></b-icon>  <span class="sr-only">Search</span>
          </template>
          <b-dropdown-item to="/supplierpayments" v-if="(isOwner||isBank)">Поставщики</b-dropdown-item>
          <b-dropdown-item to="/dealerpayments" v-if="(isOwner||isBank)">Дилеры</b-dropdown-item>
          <b-dropdown-item to="/terminalpayments" v-if="(isOwner||isBank)">Терминалы</b-dropdown-item>
          <b-dropdown-item to="/dealercash" v-if="(isOwner||isBank)">Остатки у дилера</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Терминалы" v-if="!isAdmin&&loggedIn&&isTerminals&&(isOwner||isBank||isDealer)&&!isTerminal">
          <template v-slot:button-content>
            <b-icon icon="terminal"></b-icon>  <span class="sr-only">Search</span>
          </template>
          <b-dropdown-item to="/terminalstatus" v-if="(isOwner||isBank||isDealer)">Состояние терминалов</b-dropdown-item>
          <b-dropdown-item to="/encashmentlist" v-if="(isOwner||isBank)">Список инкассации</b-dropdown-item>
          <b-dropdown-item to="/terminalcash" v-if="(isOwner||isBank||isDealer)&&!isCollector">Деньги в терминалах</b-dropdown-item>
          <b-dropdown-item to="/encashments" v-if="(isOwner||isBank||isDealer)&&!isCollector">Инкассация</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Файлы" v-if="loggedIn&&(isOwner||isBank||isDealer||isSupplier)&&!isCollector">
          <template v-slot:button-content>
            <b-icon icon="file-earmark-text"></b-icon>  <span class="sr-only">Search</span>
          </template>
          <b-dropdown-item to="/reestrs" v-if="isAllow('reestrs')">Акты сверок</b-dropdown-item>
          <b-dropdown-item to="/reestrs">Реестры</b-dropdown-item>
          <b-dropdown-item to="/reestrlogs" v-if="isAllow('reestrlogs')">Логи заданий</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Справочники" v-if="loggedIn&&!isCollector">
          <template v-slot:button-content>
            <b-icon icon="list-ul"></b-icon>  <span class="sr-only">Search</span>
          </template>
          <b-dropdown-item to="/banks" v-if="isOwner||isBank">Контрагенты</b-dropdown-item>
          <b-dropdown-item to="/suppliers" v-if="(isOwner||isBank)">Поставщики</b-dropdown-item>
          <b-dropdown-item to="/servicegroups" v-if="(isOwner||isBank)">Категории сервисов</b-dropdown-item>
          <b-dropdown-item to="/services" v-if="isOwner||isBank||isDealer">Сервисы</b-dropdown-item>
          <b-dropdown-item to="/dealers" v-if="isOwner||isBank">Дилеры</b-dropdown-item>
          <b-dropdown-item to="/terminals" v-if="isOwner||isBank||isDealer">Терминалы</b-dropdown-item>
          <b-dropdown-item to="/users" v-if="isOwner||isBank||isDealer||isSupplier">Пользователи</b-dropdown-item>
          <b-dropdown-item to="/profiles" v-if="isAllow('profiles')">Клиенты МПА</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Настройки" v-if="!isAdmin&&loggedIn&&(isOwner||isBank)&&!isTerminal&&!isCollector">
          <template v-slot:button-content>
            <b-icon icon="gear"></b-icon>  <span class="sr-only">Search</span>
          </template>
          <b-dropdown-item to="/reestrconfigs" v-if="isOwner||isBank">Реестры</b-dropdown-item>
          <b-dropdown-item to="/reestrtasks" v-if="(isOwner||isBank)">Задания</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown v-if = "loggedIn&&isDealer" v-on="getBalance()">
          <template slot="button-content">
            <em><span v-bind:style="{color: vColor}">{{balance}}</span></em>
          </template>
          <div class="balancehistory">
            <ul class="ul-balancehistory">
              <li v-for="(item) in items" v-bind:key="item.id">
                <div class="li-balancehistory" >
                  <div class="payinfo">
                    {{item.amount}}
                  </div>
                  <div class="payinfo">
                    {{item.date}}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right id="loginForm" ref="dropdown">
          <b-form class='input' v-if = "!loggedIn" >
            <b-form-input
              style="margin-bottom: 5px"
              v-model="username"
              id="dropdown-form-email"
              size="sm"
              placeholder="email"
              v-on:keyup.enter="handleSubmit('username')"
            ></b-form-input>
            <b-form-input
            style="margin-bottom: 5px"
              v-model="password"
              id="dropdown-form-password"
              type="password"
              size="sm"
              placeholder="Password"
              v-on:keyup.enter="handleSubmit('password')"
            ></b-form-input>
            <b-button style="margin-left: 50%; transform: translate(-50%, 0%)" size="sm" variant="outline-info" @click="handleSubmit('button')" v-if="!loggedIn">Ok</b-button>
              <img style="position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%)"
                    v-show="loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </b-form>
          <!-- Using button-content slot -->
          <template slot="button-content">
            <em>{{Username}}</em>
          </template>
          <b-dropdown-item @click.stop="logout" to="/"  v-if = "loggedIn">Выход</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

</div>
</template>
<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'SiteNav',
  data: () =>  ({
    balance: 0,
    username: '',
    password: '',
    fingerprint: '',
    fpComponents: [],
    vColor: 'Green',
    items: [],
    menu_items: [
      {title: "Платежи", icon: "search", if: false},
      {title: "Отчеты", icon: "graph-up"},
      {title: "Терминалы", icon: "terminal"},
      {title: "Файлы", icon: "file-earmark-text"},
      {title: "Справочники", icon: "list-ul"},
      {title: "Настройки", icon: "gear"},

    ]
  }),
  watch: {
    balance: function () {
      if (this.balance<=0) this.vColor='Red'; else this.vColor='Green';
    }
  },
  computed: {
    loggingIn () {
        return this.$store.state.authentication.status.loggingIn;
    },
    Username () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.username;
      else return 'Вход'
    },
    loggedIn () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.status.loggedIn;
      else return false
    },
    isAdmin () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='administrator'
      else return false
    },
    isGuest () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='guest'
      else return false
    },
    isCompliance () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='compliance'
      else return false
    },
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    isDealer () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Дилер'
      else return false
    },
    isSupplier () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Поставщик'
      else return false
    },
    isTerminals () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.terminals
      else return false
    },
    isTerminal () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='terminal'
      else return false
    },
    isCollector () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='collector'
      else return false
    }
  },
  methods: {
    logout () {this.$store.dispatch('authentication/logout')},
    isAllow(param) {
      if (this.$store.state.authentication.user&&this.$store.state.authentication.user.value) {
        return this.$store.state.authentication.user.value.includes(param);}
      else return false
    },
    handleSubmit () {
        const { username, password } = this;
        //this.$refs.dropdown.hide(true);
        if (username && password && !this.$store.state.authentication.user) {
            this.$store.dispatch('authentication/login', { username, password });
            this.$refs.dropdown.hide(true);
        }
    },
    getBalance () {
      if (this.isDealer) {
      axios
          .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid, type: ['PartnerBalance'],
                                                    token: this.$store.state.authentication.user.token })
          .then(response => {
                  this.balance = response.data[0].balance*100/100;
                  //if (this.balance<0) this.vColor='Red'; else this.vColor='Green';
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
      }
      setTimeout(this.getBalance, 300000)
    },
    getBalanceHistory () {
      if (this.isDealer) {
      axios
          .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid, type: ['partnerBalanceHistory'],
                                                    token: this.$store.state.authentication.user.token })
          .then(response => {
                  this.items = response.data;
                })
          .catch(error => {
            if (error.response.status === 401) {
                // auto logout if 401 response returned from api
                this.$store.dispatch('authentication/logout');
                this.$router.push('/')
            } else alert(error)
          });
      }
      setTimeout(this.getBalance, 300000)
    }
  },
  created () {
    if (this.isDealer) {
      this.getBalance();
      this.getBalanceHistory();
    }
  }
}
</script>
<style>
.input {
  margin: 6px;
}
.balancehistory {
  max-width: 250px;
  height: 200px;
  overflow: auto;
}
.ul-balancehistory {
  list-style-type: none;
  padding-inline-start: 0 !important;
  padding: 0;
}
.li-balancehistory {
  display: flex;
}
.payinfo {
  display: inline-block;
  white-space:nowrap;
  text-overflow: ellipsis;
  text-align: right;
  width: 100%;
  margin: 0 5px;
  border-bottom: 1px solid #B9B7BD;
}
</style>
