import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
/*const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}
*/

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', name: 'Home', component: () => import('@/pages/Home'), meta: { title: 'UMAI' } },
    { path: '/payments', name: 'Payments', component: () => import('@/pages/Payments'), meta: { title: 'Платежи' } },
    { path: '/dealercash', name: 'Dealercash', component: () => import('@/pages/DealerCash'), meta: { title: 'Остатки у дилера' } },
    { path: '/encashments', name: 'Encashments', component: () => import('@/pages/Encashments'), meta: { title: 'Инкассация' } },
    { path: '/terminalcash', name: 'Terminalcash', component: () => import('@/pages/TerminalCash'), meta: { title: 'Остатки в терминалах' } },
    { path: '/dealerpayments', name: 'Dealerpayments', component: () => import('@/pages/DealerPayments'), meta: { title: 'Дилеры' } },
    { path: '/terminalpayments', name: 'Terminalpayments', component: () => import('@/pages/TerminalPayments'), meta: { title: 'Терминалы' } },
    { path: '/supplierpayments', name: 'Supplierpayments', component: () => import('@/pages/SupplierPayments'), meta: { title: 'Поставщики' } },
    { path: '/servicegroups', name: 'Servicegroups', component: () => import('@/pages/ServiceGroups'), meta: { title: 'Категории' } },
    { path: '/suppliers', name: 'Suppliers', component: () => import('@/pages/Suppliers'), meta: { title: 'Поставщики' } },
    { path: '/services', name: 'Services', component: () => import('@/pages/Services'), meta: { title: 'Сервисы' } },
    { path: '/users', name: 'Users', component: () => import('@/pages/Users') },
    { path: '/banks', name: 'Partners', component: () => import('@/pages/Partners'), meta: { title: 'Контрагенты' } },
    { path: '/dealers', name: 'Dealers', component: () => import('@/pages/Dealers'), meta: { title: 'Дилеры' } },
    { path: '/profiles', name: 'Profiles', component: () => import('@/pages/Profiles'), meta: { title: 'Анкеты' } },
    { path: '/terminals', name: 'Terminals', component: () => import('@/pages/Terminals'), meta: { title: 'Терминалы' } },
    { path: '/terminalstatus', name: 'TerminalStatus', component: () => import('@/pages/TerminalStatus'), meta: { title: 'Терминалы' } },
    { path: '/encashmentlist', name: 'EncashmentList', component: () => import('@/pages/EncashmentList'), meta: { title: 'Список инкассации' } },
    { path: '/reestrs', name: 'Reestrs', component: () => import('@/pages/Reestrs') },
//    { path: '/reestrconfigs', name: 'ReestrConfigs', component: ReestrsPage, meta: { title: 'Банки' } },
//    { path: '/reestrlogs', name: 'ReestrLogs', component: ReestrsPage, meta: { title: 'Дилеры' } },
//    { path: '/reestrtasks', name: 'ReestrTasks', component: ReestrsPage, meta: { title: 'Терминалы' } },
    { path: '/paymentlogs', name: 'PaymentLogs', component: () => import('@/pages/PaymentLogs'), meta: { title: 'Логи платежей' } },
    { path: '/payment', name: 'Payment', component: () => import('@/pages/Payment'), meta: { title: 'Проведение платежа' } },
    //{ path: '/services', name: 'Services', component: ServicesPage },
    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
  });

  router.beforeEach((to, from, next) => {
    //let deletingAll = browser.history.deleteAll()
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    document.title = to.meta.title ? to.meta.title : 'UMAI';

    if (authRequired && !loggedIn) {
      return next('/');
    }

    next();

  })
